<template>
  <div>
    <v-text-field
      ref="inputField"
      v-bind="$attrs"
      :label="this.label || $translation.t('Business name')"
      outlined
      v-model="model"
      :rules="[rules.required]"
      hide-details="auto"
      :readonly="disabled"
      :class="{ 'input-disabled': disabled }"
    >
    </v-text-field>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import projectInputFields from "@/utils/mixins/projectInputFields";

export default {
  name: "BusinessNameField",
  props: {
    value: String,
    label: String,
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    rules,
    model: "",
  }),
  created() {
    if (this.global) return;

    this.model = this.name ?? "";
  },
  mounted() {
    this.model = this.value ?? this.model;
  },
  computed: {
    name() {
      return this.projectInformationValue("business_name");
    },
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.model = newValue;
    },
    name(newValue) {
      if (this.global) this.model = newValue;
    },
  },
};
</script>
